import React from "react";
import SingleTeam from "../../components/Team/SingleTeam";
import SectionTitle from "../../components/Common/SectionTitle";

// Images
import serve1Img from "../../assets/img/about/who we serve/Who we serve1.jpeg";
import serve2Img from "../../assets/img/about/who we serve/Reunification.jpg";
import succeedImg from "../../assets/img/about/how-we-succeed-2.jpg";
import descImg from "../../assets/img/about/DOS-2.jpg";
import SliderStyleTwo from "../../components/Slider/SliderStyleTwo";

const Team = () => {
  return (
    <React.Fragment>
      <div id="rs-about" className="rs-about style9 pb-50">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-12 md-mt-40">
              <div className="content d-md-none">
                <div className="sub-title mb-20 ">Who We Serve</div>
              </div>
              <SliderStyleTwo />
            </div>
            <div className="col-lg-8 pl-100 md-pl-14 col-md-12">
              <div className="content">
                <div className="sub-title mb-20 d-none d-md-block">
                  Who We Serve
                </div>
                <h4 className="sl-title mb-20 md-mb-20">Eligibility</h4>
                <p className="desc mb-30">
                  Currently, we serve individuals between the ages of 6 to 90.
                  Also, families eligible for non-intensive family preservation
                  are those with one or more children ages 5 through 18 years
                  who are at risk for current or future role dysfunction that
                  could lead to out-of-home placement. Referrals are accepted
                  from any source, to include a family.
                </p>

                <div className="content white-bg">
                  <div className="course-overview">
                    <div className="inner-box">
                      <h3>
                        Risk Factors include, but are not limited to, the
                        following:
                      </h3>
                      <div className="row">
                        <div className="col-lg-6">
                          <ul className="review-list">
                            <li>Lack of effective parenting</li>
                            <li>Family disruption/instability</li>
                            <li>Substance abuse</li>
                            <li>Abuse/neglect</li>
                            <li>Behavioral difficulties</li>
                            <li> Court/law enforcement involvement</li>
                            <li>Low income</li>
                          </ul>
                        </div>
                        <div className="col-lg-6">
                          <ul className="review-list">
                            <li>Academic/truancy problems</li>
                            <li>Developmental disabilities</li>
                            <li>Intergenerational difficulties</li>
                            <li>Mental/emotional problems</li>
                            <li>Family violence</li>
                            <li>Teen pregnancy</li>
                            <li>Health problems</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="rs-about" className="rs-about style9 pb-10">
        <div className="container">
          <div className="row align-items-center">
          <div className="col-lg-5 order-md-1 col-md-12 md-mb-40">
              <div className="img-part">
                <img src={serve2Img} alt="Image" />
              </div>
            </div>
            <div className="col-lg-7 pr-100 md-pr-14 col-md-12 md-mb-30">
              <div className="content ">
                <p className="desc mb-0">
                  Reunification services, as a Non-Intensive, are provided to
                  families who have one or more children returning from an
                  out-of-home placement. The populations of children for whom
                  this service shall be made available include those alleged or
                  found to be abused, neglected or dependent, emotionally or
                  behaviorally disturbed; undisciplined or delinquent; and/or
                  have medical needs, that the assistance could be managed in
                  the home.
                </p>
              </div>
            </div>
           
          </div>
        </div>
      </div>
      <div id="rs-about" className="rs-about style9 pt-100 pb-100 md-pt-50 md-pb-50">     
      <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-5 col-md-12 md-mb-20 mb-40">
          <div className="content d-md-none">
            <div className="sub-title mb-20">How We Succeed</div>
          </div>
          <div className="img-part">
            <img src={succeedImg} alt='Image' />
          </div>
        </div>
        <div className="col-lg-7 pl-100 md-pl-14 col-md-12">
          <div className="content">
            <div className="sub-title mb-20 d-none d-md-block">How We Succeed</div>
            <div className="content white-bg">
              <div className="course-overview">
                <div className="inner-box">

                  <p className="desc">
                  Firebird, LLC provides individualized services to assist
                  individuals to recognize their strengths and overcome
                  challenges that surround their lives. Our approach is client
                  centered so that each person can process information based on
                  their most effective learning style to assist them with their
                  transition. Our programs use auditory, visual, linguistic and
                  kinetic learning to reinforce behavior modification. Our
                  belief is that building a quality worker/client relationship
                  enhances the process of assisting each client in learning
                  behavioral coping skills. By providing behavioral management
                  tools, the client will be able to make better life choices in
                  the future.
                  </p>



                </div>
              </div>

            </div>

          </div>
        </div>

      </div>
    </div>
   
      </div>
      <div
        id="rs-about"
        className="rs-about style9 pt-50 pb-50 md-pt-40 md-pb-30 descbg"
      >
        <div className="container-fluid">
          <div className="row align-items-center">
          <div className="col-lg-4 order-md-1 col-md-12 md-mb-40">
          <div className="content d-md-none">
                <div className="sub-title mb-20">Description of Services</div>
                </div>
              <div className="img-part">
                <img src={descImg} alt="Image" />
              </div>
            </div>
            <div className="col-lg-8 col-md-12">
              <div className="content">
                <div className="sub-title mb-20 d-none d-md-block">Description of Services</div>

                <div className="content">
                  <div className="course-overview">
                    <div className="inner-box">
                      <div className="row">
                        <div className="col-lg-6">
                          <ul className="review-list">
                            <li>
                              Services are targeted to families with children at
                              risk for current or future role dysfunction in
                              home or community, due to a variety of
                              socio-cultural, physical, psychological, and
                              academic factors;
                            </li>
                            <li>
                              Services are time limited provided for a maximum
                              of six months;
                            </li>
                            <li>
                              Services are home-based At least one-half of a
                              caseworker’s time spent providing family
                              preservation services to a family is spent in face
                              to face contact in the family’s home and
                              community;
                            </li>
                            <li>
                              Services focus on promoting family competence and
                              stability – building on strengths and the families
                              own resources;
                            </li>
                            <li>
                              Services are provided in a culturally competent
                              manner with understanding of and respect for
                              cultural and ethnic diversity;
                            </li>
                          </ul>
                        </div>
                        <div className="col-lg-6">
                          <ul className="review-list">
                            <li>
                              The services provided are both therapeutic and
                              concrete;
                            </li>
                            <li>
                              All Firebird, LLC clients have access to
                              crisis/emergency services 24 hours a day, seven
                              days a week;
                            </li>
                            <li>
                              Caseloads range from eight families at a time,
                              based on the intensity and duration of services
                              and the severity of the problems, as recommended
                              by Caseworkers, Parents, Probation and Parole
                              Officers, WIN Workers and approved by Firebird,
                              LLC Manager;
                            </li>
                            <li>
                              Firebird, LLC’s Caseworkers have specialized
                              training and support to provide this service.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
           
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Team;
