import React from "react";
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Header from '../../components/Layout/Header/Header';
import Footer from '../../components/Layout/Footer/Footer';
import OffWrap from '../../components/Layout/Header/OffWrap';
import footerLogo from '../../assets/img/logo/lite-logo.png';


const PDFViewer = () => {
    const location = useLocation();
    const { data } = location.state;


    useEffect(() => {

    }, []);

    return (
        <React.Fragment>
            <OffWrap />
            <Header
                TopBar='enable'
                emailAddress='admin@firebirdllc.com'
                Location='601 S 10th St., #104, Las Vegas, Nevada 89101'
                parentMenu='home'
            />
            <div className='pageOffset'>
                <div className="container">
                    <object id="pdfViewer"
                        data={data + "#toolbar=0"}
                        type="application/pdf"
                    />
                </div>
            </div>
            <Footer
                footerClass="rs-footer home9-style main-home"
                footerLogo={footerLogo}
            />
        </React.Fragment>
    )
}

export default PDFViewer