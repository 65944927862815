import React from 'react';
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import serve1Img from '../../assets/img/about/who we serve/About-Eligibility-1.jpg'
import serve2Img from '../../assets/img/about/who we serve/Who-We-Serve.jpg'

const SliderStyleTwo = () => {

    const sliderSettings = {
        dots: false,
        centerMode: false,
        infinite: true,
        arrows: true,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    arrows: false,
                }
            }
        ]
    };

    return (
        <React.Fragment>
            <div className="rs-slider style1 aboutSlider">
                <Slider {...sliderSettings}>
                    <div className="slider-content">
                        <div className="">
                            <div className="col-lg-12 col-md-12 md-mb-20">
                                <div className="img-part h-100">
                                    <img src={serve1Img} alt='Image'/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="slider-content">
                        <div className="">
                            <div className="col-lg-12 col-md-12 md-mb-20">
                                <div className="img-part h-100">
                                    <img src={serve2Img} alt='Image' />
                                </div>
                            </div>
                        </div>
                    </div>
                </Slider>
            </div>
        </React.Fragment>
    );
}

export default SliderStyleTwo;