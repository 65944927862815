import React from 'react';
import { Link } from 'react-router-dom';

const CTA = () => {

    return (
        <div className="rs-cta home9-style">
            <div className="partition-bg-wrap">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-8">
                        <div className="sec-title3 mb-40 md-mb-10">
                                <div className="sub-title big">Caseload and Length of Service:</div>
                                {/* <h2 className="title white-color pr-70 sm-pr-0">COVID-19 information and updates</h2> */}
                                <div className="desc white-color mb-40 ">
                                Caseloads range from eight families at a time, based on the intensity and duration of services and the severity of the problems, as recommended by DFS Caseworkers, WIN Workers, Parole and Probation Officers and approved by Firebird, LLC Manager or Directors. Non-Intensive Firebird, LLC Services programs range in models from early intervention to reunification, thus intensity and duration of services vary. Initial contacts with a family will be more frequent; intensity of service will decrease as the family becomes more self-sufficient. Families can be served for up to six months. This standard was set to allow flexibility in response to individual family service needs while at the same time endorsing the principles of a time limited intervention model that takes advantage of the discomfort and disequilibrium that occurs in a crisis to generate positive changes within a family. It means that planning for termination of the service is incorporated in the treatment plan and goal setting with the family from the beginning.Following termination of service, the Non-Intensive Firebird, LLC Services caseworker may need to be available to the family for brief interventions “booster shots” – if there is an issue related to the work that was accomplished during the original intervention. These types of contacts are typically initiated by the family and serve to reinforce learning or progress that has been made. The Program may consider restarting services for the family if needed.
                                </div>
                                
                            </div>
                        </div>
                        <div className="col-lg-4 pl-90 md-pl-14">
                            <div className="sec-title3 mb-40 md-mb-10">
                                <div className="sub-title big">Staff Qualifications</div>
                                {/* <h2 className="title white-color pr-70 sm-pr-0">COVID-19 information and updates</h2> */}
                                <div className="desc white-color md-mb-0 mb-40">
                                Each Non-Intensive Firebird, LLC Program must have written Policies that address the educational and experience qualifications for Family Preservation Services caseworkers. Caseworkers / PSR must have a Bachelor’s Degree in Human Services or a related field. Basic Skills Trainers / Mentors must have a High School Diploma and experience working with youth with Behavioral Issues. The Program assures that staff has the body of knowledge, competence and practice skills required to effectively provide family preservation services. The Program has a written plan for orientation, family-centered training and on-going staff development.


                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CTA;