import React from 'react';
import BlogPart from '../../components/Blog/HomeBlogSection';
import SectionTitle from '../../components/Common/SectionTitle';

const Blog = () => {
    return (
        <React.Fragment>
            <div id="rs-blog" className="rs-blog main-home pb-100 pt-100 md-pt-70 md-pb-70">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title3 text-center"
                        subtitleClass="sub-title"
                        subtitle="Firebird, LLC.’s goal is to help our clients create productive, happier lives by providing quality professional services. Our employee/contractors play a vital role in ensuring that our commitment to excellence is reflected in our service to clients."
                        titleClass="title mb-0"
                        title="FIREBIRD LLC"
                    />
                    {/* <BlogPart /> */}
                </div>
            </div>
        </React.Fragment>
    );

}

export default Blog;