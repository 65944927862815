import React from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import FeatureStyle1 from "../Feature/FeatureStyle1";
import video1 from "../../assets/video/vid-1.mp4";
import video2 from "../../assets/video/vid-2.mp4";

const SliderDefault = () => {
  const sliderSettings = {
    fade: true,
    speed: 2000,
    autoplaySpeed: 6000,
    dots: false,
    centerMode: false,
    infinite: true,
    autoplay:true,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
        },
      },
    ],
  };

  return (
    <React.Fragment>
      <div className="rs-slider main-home">
        <Slider {...sliderSettings}>
          <div className="slider-content slide1">
            <div className="bgMedia">
              {/* <img src={slide1} alt="Slide 1" /> */}
              <video autoPlay loop muted>
                <source src={video1} type="video/mp4" />
              </video>
            </div>
            <div className="container">
             
            </div>
          </div>

          <div className="slider-content slide2">
            <div className="bgMedia">
              <video autoPlay loop muted>
                <source src={video2} type="video/mp4" />
              </video>
            </div>

            <div className="container">
            
            </div>
          </div>
        </Slider>
        {/* <FeatureStyle1 /> */}
      </div>
    </React.Fragment>
  );
};

export default SliderDefault;
