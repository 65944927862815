import React from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../../components/Common/SectionTitle';

const Cta = () => {

    return (
        <div className="rs-cta main-home">
            <div className="partition-bg-wrap">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <SectionTitle
                                sectionClass="sec-title3"
                                titleClass="title white-color mb-16 new-font"
                                title="“ When adversity strikes, that’s when you have to be the most calm. Take a step back, stay strong, stay grounded and press on. – LL Cool J”"
                                descClass="desc white-color pr-100 md-pr-0 "
                            />
                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Cta;