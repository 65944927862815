import React from 'react';
import Team from './TeamSection';
import ScrollToTop from '../../components/Common/ScrollTop';

const SESMain = () => {

    return (
        <React.Fragment>
            <Team />
            <ScrollToTop
                scrollClassName="scrollup orange-color"
            />
        </React.Fragment>
    )
}

export default SESMain;