import React, { useState } from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import SectionTitle from '../../components/Common/SectionTitle';

// About Image
import countIcon1 from '../../assets/img/about/style3/icons/1.png';
import countIcon2 from '../../assets/img/about/style3/icons/2.png';
import countIcon3 from '../../assets/img/about/style3/icons/3.png';

const AboutCounter = () => {

    const [state, setState] = useState(true);

    const counters = [
        {
            countNum: 2,
            countTitle: 'Students',
            counterPrefix: 'k+',
            countIcon: countIcon1
        },
        {
            countNum: 50,
            countTitle: 'Average CGPA',
            counterPrefix: '',
            countIcon: countIcon2
        },
        {
            countNum: 95,
            countTitle: 'Graduates',
            counterPrefix: '%',
            countIcon: countIcon3
        }

    ];

    return (
        
             
            <div id="rs-about" className="rs-about style1 pt-80 md-pt-30 pb-80 md-pb-0">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="about-part">
                            <div className="sec-title mb-0">
                                <h2 className="title">Our Philosophy</h2>
                                <h2 className="new-font res-text mb-0">Firebird, LLC.’s goal is to help our clients create productive, happier lives by providing quality professional services. Our employee/contractors play a vital role in ensuring that our commitment to excellence is reflected in our service to clients.</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       
    );
}

export default AboutCounter;