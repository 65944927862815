import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import FooterBottom from './FooterBottom';

const Footer = (props) => {
    const location = useLocation();
    const { footerLogo, footerClass, footerTopClass } = props;
    return (
        <footer className={footerClass ? footerClass : 'rs-footer'}>
            <div className={`footer-top ${footerTopClass}`}>
                <div className="container">
                    <div className="row row-cols-lg-5">
                        <div className="col-md-12 col-sm-12 footer-widget md-mb-50">
                        
                         
                            <ul className="site-map">
                                <li><Link to="/about" className={location.pathname === '/about' ? 'active' : ''}>About</Link></li>
                              
                            </ul>
                        
                        </div>
                        <div className="col-md-12 col-sm-12 footer-widget md-mb-50">
                           
                            <ul className="site-map">
                                <li><Link to="/contact" className={location.pathname === '/contact' ? 'active' : ''}>Contact Us</Link></li>
                               
                            </ul>
                        </div>
                        <div className="col-md-12 col-sm-12 footer-widget md-mb-50">
                            
                            <ul className="site-map">
                                <li><Link to="/covid-19" className={location.pathname === '/covid-19' ? 'active' : ''}>Covid-19</Link></li>
                             
                            </ul>
                        </div>
                        <div className="col-md-12 col-sm-12 footer-widget md-mb-50">
                           
                            <ul className="site-map">
                                <li><Link to="/pre-ses" className={location.pathname === '/pre-ses' ? 'active' : ''}>Firebird LLC ILP</Link></li>
                                
                            </ul>
                        </div>
                        <div className="col-md-12 col-sm-12 footer-widget">
                           
                            <ul className="site-map">
                                <li><a href="https://portal.office365.com" target='_blank' >Employee Login</a></li>
                                
                            </ul>
                        </div>
                    </div>
                    <div className='col text-center '>
                    <h3 className='text-white'>Firebird LLC</h3>
                    <div>
                        <p className='text-white mb-0'>601 S 10th St., #104, Las Vegas, Nevada 89101</p>
                    </div>
                    </div>
                </div>
            </div>
            <FooterBottom />
    
        </footer>
    );
}

export default Footer;