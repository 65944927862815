import React from 'react';
import Team from './TeamSection';
import Blog from './BlogSection';
import AboutVideo from './VideoSection';
import AboutCounter from './CounterSection';
import Testimonial from './TestimonialSection';
import ScrollToTop from '../../components/Common/ScrollTop';
import CTA from './CtaSection';


const CovidMain = () => {

    return (
        <React.Fragment>
            {/* AboutCounter Start */}
            {/* <AboutCounter /> */}
            {/* AboutCounter End */}
        <Team/>

        
			<ScrollToTop
				scrollClassName="scrollup orange-color"
			/>
			{/* scrolltop-end */}

        </React.Fragment>
    )
}

export default CovidMain;