import React from "react";
import SingleTeam from "../../components/Team/SingleTeam";
import SectionTitle from "../../components/Common/SectionTitle";

// Images
import serve1Img from '../../assets/img/about/who we serve/Who we serve1.jpeg'
import serve2Img from '../../assets/img/about/who we serve/Who we serve 2.jpeg'
import succeedImg from '../../assets/img/about/How-we-succeed.jpeg'
import descImg from '../../assets/img/about/description-services.jpeg'


const Team = () => {
  return (
    <React.Fragment>
         <div id="rs-about" className="rs-about style9 pt-100 pb-100 md-pt-40 md-pb-40" >
        <div className="container">
          <div className="row align-items-center">         
            <div className="col-md-12">
              <div className="content">
                <h4 className="text-center mb-0">
                FIREBIRD LLC’S UTMOST PRIORITY IS THE HEALTH, SAFETY, AND WELL-BEING OF OUR STAFF AND THE YOUTH IN OUR CARE. TO PREVENT THE TRANSMISSION OF COVID-19, AS WELL AS OTHER CORONAVIRUSES AND INFLUENZA, FIREBIRD LLC ACTIVELY ADHERES TO ALL LOCAL, STATE AND FEDERAL HEALTH STANDARDS INCLUDING THOSE PROVIDED BY THE CENTERS FOR DISEASE CONTROL AND PREVENTION.
                </h4>
              </div>
            </div>
           
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Team;
