import React, { useEffect, useRef, useState } from "react";
import { Link } from 'react-router-dom';
import AOS from 'aos';
import Header from '../../components/Layout/Header/Header';
import Footer from '../../components/Layout/Footer/Footer';
import OffWrap from '../../components/Layout/Header/OffWrap';
import Slider from "react-slick";
import serve2Img from '../../assets/img/about/who we serve/Reunification.jpg';
import footerLogo from '../../assets/img/logo/lite-logo.png';


const PreSes = () => {
    useEffect(() => {
        AOS.init();
    })

    const [autoplaySpeed, setAutoplaySpeed] = useState(5000);
    const sliderRef = useRef(null);

    const sliderSettings = {
        fade: true,
        speed: 900,
        autoplaySpeed: autoplaySpeed,
        dots: false,
        centerMode: false,
        infinite: true,
        autoplay: true,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        touchThreshold: 100,
        adaptiveHeight: true,  
        beforeChange: (current, next) => {
            if (next === 1) { 
                setAutoplaySpeed(5000);
            } if (next === 4) { 
                setAutoplaySpeed(8000);
            } else {
                setAutoplaySpeed(5000);
            }
        }   
    };
    return (
        <React.Fragment>
            <OffWrap />
            <Header
                TopBar='enable'
                emailAddress='admin@firebirdllc.com'
                Location='601 S 10th St., #104, Las Vegas, Nevada 89101'
                parentMenu='home'
            />
            <div className='pageOffset'>
                <div className="bannerArea">
                    <img src={serve2Img} />
                    <div className="verticalSlider">
                        <div className="container">
                            <Slider ref={sliderRef} {...sliderSettings}>
                                <div className="slider-content" data-aos="zoom-in">
                                &ldquo;You can choose to follow the rules, or you can choose to break them.&rdquo;
                                </div>
                                <div className="slider-content" data-aos="zoom-in">
                                &ldquo;You can choose to respect those who care about you, or you can choose to be disrespectful.&rdquo;
                                </div>
                                <div className="slider-content" data-aos="zoom-in">
                                &ldquo;What choices are you going to make?&rdquo;
                                </div>
                                <div className="slider-content" data-aos="zoom-in">
                                &ldquo;Nothing is impossible to a willing heart." — Abraham Lincoln&rdquo;
                                </div>
                                <div className="slider-content" data-aos="zoom-in">
                                    <Link className="readon green-banner" to="/ses">Click here to view Safe Environmental Standards page</Link>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
            <Footer
                footerClass="rs-footer home9-style main-home"
                footerLogo={footerLogo}
            />
        </React.Fragment>
    )
}

export default PreSes