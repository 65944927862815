import React from 'react';
import { Link } from 'react-router-dom';

const TopHeader = (props) => {
	const { topBarClass, emailAddress, phoneNumber, Location,externalLink } = props;

    return (
        <div className={topBarClass ? topBarClass : "topbar-area home8-topbar hidden-md"}>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-7">
                        <ul className="topbar-contact">
                            {emailAddress ? 
                                <li>
                                    <i className="flaticon-email"></i>
                                    <a href={'mailto:' + emailAddress}>{emailAddress}</a>
                                </li> : ""
                            }
                            {phoneNumber ? 
                                <li>
                                    <i className="flaticon-call"></i>
                                    <a href={'tel:+' + phoneNumber}>{phoneNumber}</a>
                                </li> : ""
                            }
                            {Location ? 
                                <li>
                                    <i className="flaticon-location"></i>
                                    {Location}
                                </li> : ""
                            }
                        </ul>
                    </div>
                    <div className='col-md-5'>
                        <ul className='topbar-contact text-right'>
                        <li><a href="https://portal.office365.com" target='_blank' >Employee Login</a></li>
                        </ul>

                    </div>
                 
                </div>
            </div>
        </div>
    );
}

export default TopHeader;