import React from 'react';


const Newsletter = (props) => {
    const { sectionClass, wrapperClass, titleClass, iconPath } = props;

    return (
        <div className={sectionClass ? sectionClass : 'rs-newsletter style1 orange-color mt-50 sm-mt-40 sm-mb-0 md-pb-50'}>
            <div className="container">
                <div className={wrapperClass ? wrapperClass : 'newsletter-wrap'}>
                    <div className="row y-middle">
                        <div className="col-lg-12 col-md-12">
                            <div className="content-part">
                                <div className="sec-title text-center">
                                    <h2 className={titleClass ? titleClass : 'title mb-0 white-color new-font'}> Advocates for youth and behavioral health services.</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Newsletter;