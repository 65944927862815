import React from 'react';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
import SectionTitle from '../../components/Common/SectionTitle';
import ContactForm from '../../components/Contact/ContactForm';
import ContactInfo from '../../components/Contact/ContactInfo';

import bannerbg from '../../assets/img/breadcrumbs/inner13.jpg';


const ContactMain = () => {

    return (
        <React.Fragment>

            {/* SiteBreadcrumb */}
            {/* <SiteBreadcrumb
                pageTitle="Contact"
                pageName="Contact"
                breadcrumbsImg={bannerbg}
            /> */}
            {/* SiteBreadcrumb */}

            {/* Contact Section Start */}
            <div className="rs-contact style1 event-bg pt-100 md-pt-50 pb-100 md-pb-40">
                <div className="container pb-10 md-pb-5">
                    <div className="row gutter-35">
                        <div className="col-md-4 sm-mb-30">
                            <ContactInfo
                                boxClass=""
                                title="Address"
                                iconClass="flaticon-location"
                                address="601 S 10th St., #104, Las Vegas, Nevada 89101"
                            />
                        </div>
                        <div className="col-md-4 sm-mb-30">
                            <ContactInfo
                                boxClass=""
                                title="Email Address"
                                iconClass="flaticon-email"
                                email="admin@firebirdllc.com"
                            />
                        </div>
                        <div className="col-md-4 sm-mb-30">
                            <ContactInfo
                                boxClass=""
                                title="Phone Number"
                                iconClass="flaticon-phone"
                                phone="(702) 382-4061"
                            />
                        </div>
                    </div>
                </div>

               
            </div>
            {/* Contact Section End */}
        </React.Fragment>

    );
}


export default ContactMain;