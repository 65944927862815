import React from "react";
import { Link } from 'react-router-dom';
import annualReport from '../../assets/doc/Annual-PREA-Report.pdf'
import grievancePolicy from '../../assets/doc/FIREBIRD-RESIDENT-GRIEVANCE-Policy.pdf'
import behaiviorPolicy from '../../assets/doc/Firebird-Behavioral-Intervention-Meeting-Policy.pdf'
import grievanceForm from '../../assets/doc/GRIEVANCE-Form.pdf'
import procedurePolicy from '../../assets/doc/FIREBIRD-ILP-PREA-Policy.pdf'
import auditReport from '../../assets/doc/Firebird-PREA-Final-Report-2024.pdf'

const Team = () => {
  return (
    <React.Fragment>
      <div id="rs-about" className="rs-about style9 pt-30 pb-30 md-pt-70 md-pb-0" >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="content">

                <p className="desc mb-0">
                  Firebird LLC ILP is committed to providing a safe environment free from sexual abuse for the residents in our care. To this end, Firebird LLC ILP has developed Safe Environmental Standards in accordance with the Prison Rape Elimination Act (PREA).
                </p>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div id="rs-about" className="rs-about style9 pt-30 pb-30 md-pt-70 md-pb-0" >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="content">
                <div className="sub-title mb-10">
                  ZERO TOLERANCE POLICY
                </div>
                <p className="desc mb-0">
                  Firebird LLC ILP has a Zero Tolerance Policy regarding sexual assault, sexual harassment and sexual activity. All residents in our programs have a right to be free from sexual assault or harassment, as well as free from retaliation for reporting. There is no “consensual” sexual activity between residents or between staff and residents at Firebird LLC ILP programs. (§115.311)
                </p>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div id="rs-about" className="rs-about style9 pt-30 pb-30 md-pt-70 md-pb-0" >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="content">
                <div className="sub-title mb-10">
                  REPORTING
                </div>
                <p className="desc mb-0">
                  Residents are encouraged to report sexual harassment or assault by another resident or a staff member. They may report to any staff member, to an outside agency (phone numbers on posters throughout the program), or anonymously by writing it down and submitting it to any grievance box in living area.
                  Firebird LLC ILP accepts third-party reports of sexual assault or sexual harassment from a friend or family member of a resident (§115.354). Third-party reporting forms are available at the front desk of our programs or can be <a href={grievanceForm} target="_blank" >downloaded here</a>. If you suspect sexual abuse, you may also call Firebird LLC ILP at (702) 382-4061 to report it or report it to the Las Vegas Metropolitan Police Department where the allegations occurred. All reports are taken seriously and investigated.
                  Staff must report any knowledge or suspicion of sexual harassment or sexual assault to their supervisor immediately. Staff may also privately report to their site PREA Compliance Manager.There is often concern that addressing PREA-related issues in policy and procedure and housing Residents as to their right to be free from sexual abuse, may result in false accusations or false reports of staff misconduct. All allegations will be thoroughly, and timely investigated, and knowingly false allegations may be prosecuted.

                </p>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div id="rs-about" className="rs-about style9 pt-30 pb-30 md-pt-70 md-pb-0" >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="content">
                <div className="sub-title mb-10">
                  INVESTIGATIVE POLICY
                </div>
                <p className="desc mb-0">
                  Firebird LLC ILP will ensure that an administrative investigation is completed for all allegations of sexual abuse and sexual harassment. Allegations of sexual abuse or sexual harassment will be referred for investigation to the local agency with the legal authority to conduct criminal investigations, unless the allegation does not involve potentially criminal behavior. (Safe Environmental Standards Policy/PREA Standard §115.322). The local agency is Las Vegas Metropolitan Police.  Phone:  911
                </p>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div id="rs-about" className="rs-about style9 pt-30 pb-30 md-pt-70 md-pb-0" >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="content">
                <div className="sub-title mb-10">
                  SEXUAL ABUSE REPORTING
                </div>
                <p className="desc mb-0">
                  In compliance with Firebird LLC ILP’s Safe Environmental Standards Policies, regarding publication of annual reporting and aggregated sexual abuse data, Firebird LLC ILP will report each year using the U.S. Department of Justice Survey of Sexual Violence Summary form, regarding founded allegations of sexual abuse in our programs (§115.388, §115.389). Firebird LLC ILP continuously educates all staff, students, contractors, and volunteers on Safe Environmental Standards regarding the importance of protecting residents from sexual abuse.
                </p>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div id="rs-about" className="rs-about style9 pt-30 pb-30 md-pt-70 md-pb-0" >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="content">
                <div className="sub-title mb-10">
                  FIREBIRD LLC ILP PREA POLICY AND PROCEDURE
                </div>
                <p className="desc mb-0">
                  To View Firebird LLC ILP Policy and Procedures <Link to={{ pathname: '/pdf-viewer', state: { data: procedurePolicy }}} >Click here</Link>
                </p>
                <p className="desc mb-0">
                  To View Firebird LLC ILP Resident Grievance Policy <Link to={{ pathname: '/pdf-viewer', state: { data: grievancePolicy }}} >Click here</Link>
                </p>
                <p className="desc mb-0">
                  To View Firebird LLC ILP Resident Behavioral Intervention Policy  <Link to={{ pathname: '/pdf-viewer', state: { data: behaiviorPolicy }}} >Click here</Link>
                </p>
                <p className="desc mb-0">
                  To View Firebird LLC ILP Grievance Form<a href={grievanceForm} download > Click here.</a>
                </p>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div id="rs-about" className="rs-about style9 pt-30 pb-30 md-pt-70 md-pb-0" >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="content">
                <div className="sub-title mb-10">
                  FIREBIRD LLC ILP ANNUAL PREA REPORT
                </div>
                <p className="desc mb-0">
                  To view Firebird LLC ILP’s 2023 PREA Report, <Link to={{ pathname: '/pdf-viewer', state: { data: annualReport }}} >click here</Link>.
                </p>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div id="rs-about" className="rs-about style9 pt-30 pb-30 md-pt-35 md-pb-40" >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="content">
                <div className="sub-title mb-10">
                  DEPARTMENT OF JUSTICE AUDIT REPORT
                </div>
                <p className="desc mb-0">
                  To view the 2024 Department of Justice Audit Report, <Link to={{ pathname: '/pdf-viewer', state: { data: auditReport }}} >click here</Link>.
                </p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Team;
