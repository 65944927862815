import React from "react";
import SingleTeam from "../../components/Team/SingleTeam";
import SectionTitle from "../../components/Common/SectionTitle";

// Images
import serve1Img from '../../assets/img/services/service-1.jpg'
import serve2Img from '../../assets/img/services/service-2.jpg'
import serve3Img from '../../assets/img/services/service-3.jpg'
import serve4Img from '../../assets/img/services/service-4.jpg'
import succeedImg from '../../assets/img/about/How-we-succeed.jpeg'
import descImg from '../../assets/img/about/description-services.jpeg'


const Team = () => {
  return (
    <React.Fragment>
      <div id="rs-about" className="rs-about style9 pb-50 md-pt-70 md-pb-0 ">
        <div className="container">
          <h2 className="text-center" >Non-Intensive Required Services:</h2>
          <div className="row">
            <div className="col-lg-4 col-md-12 md-mb-20 mb-40">
              <div className="content d-md-none">
                <div className="sub-title mb-20">Family Assessment:</div>
              </div>
              <div className="img-part sideCoverImg">
                <img src={serve1Img} alt='Image' />
              </div>
            </div>
            <div className="col-lg-8 pl-100 md-pl-14 col-md-12">
              <div className="content">
                <div className="sub-title mb-20 d-none d-md-block">Family Assessment:</div>
                {/* <h4 className="sl-title mb-20 md-mb-20">Eligibility</h4> */}


                <div className="content white-bg">
                  <div className="course-overview">
                    <div className="inner-box">

                      <p className="desc">Family assessment is an ongoing process which continues from intake through termination of services. The purpose of the family assessment is:</p>
                      <div className="row">
                        <div className="col-lg-12">
                          <ul className="review-list">
                            <li>To identify the family’s strengths, resources, needs, and weaknesses;</li>
                            <li>To help family members identify measurable, realistic, achievable, and time-limited treatment goals; and</li>
                            <li>To help family members develop strategies for achieving their goals.</li>

                          </ul>
                        </div>

                      </div>
                      <p className="desc">The family assessment is comprehensive and considers the family’s strengths as well as needs within its entire social context (i.e. nuclear family, extended family, school, work, church, neighborhood, etc.). Family assessment information is gathered through:</p>
                      <div className="row">
                        <div className="col-lg-12">
                          <ul className="review-list">
                            <li>Ongoing observation of family members in their natural environment;</li>
                            <li>Family and individual meetings;</li>
                            <li>A social history gathered over time through interactions with family members;</li>
                            <li>Reports from other professionals; and</li>
                            <li>Assessment instruments completed by/with family members.</li>

                          </ul>
                        </div>

                      </div>
                      <p className="desc">Specialized assessments are considered on a case-by-case basis. These may include referrals for neurological, nutritional, speech and language, developmental, offender, and psychological assessments; alcohol, drug, and toxic metal screening, blood chemistry and vocational evaluations, etc.</p>

                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="rs-about"
        className="rs-about style9 pb-10 md-pt-40"
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 order-md-1 col-md-12 md-mb-20 mb-40">
              <div className="content d-md-none">
                <div className="sub-title mb-20 ">Family and Individual Counseling:</div>
              </div>
              <div className="img-part">
                <img src={serve2Img} alt='Image' />
              </div>
            </div>
            <div className="col-lg-7 pr-100 md-pr-14 md-pb-20 col-md-12">
              <div className="content">
                <div className="sub-title mb-20 d-none d-md-block">Family and Individual Counseling:</div>
                <div className="content white-bg">
                  <div className="course-overview">
                    <div className="inner-box">

                      <p className="desc">Counseling in this context means the therapeutic interaction between Firebird, LLC caseworkers and family members focused on problem-solving and skill-building. Counseling services may include individual, couple, family, and group counseling. During counseling, the worker and family members:</p>
                      <div className="row">
                        <div className="col-lg-12">
                          <ul className="review-list">
                            <li>Establish a trusting relationship.</li>
                            <li>Define and clarify family issues and perceptions.</li>
                            <li>Assess the need for change and develop and implement strategies for making those changes.</li>
                            <li>Evaluate progress and make appropriate changes in strategies and goals and</li>
                            <li>Effectively terminate the relationship.</li>

                          </ul>
                        </div>

                      </div>


                    </div>
                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="rs-about" className="rs-about style9 pb-50 md-pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 md-mb-20">
              <div className="content">

                <div className="sub-title mb-10">Client advocacy:</div>
                <p className="desc">
                  This means providing the family with information about the services available to them in their community and helping the family to obtain them. Firebird, LLC caseworker initially intervenes with other service providers as needed on behalf of the family and models advocacy skills for the family. Firebird, LLC worker also helps the family to develop the skills needed to negotiate with bureaucracies and services systems to obtain needed help.
                </p>
              </div>
            </div>
            <div className="col-lg-12 col-md-12">
              <div className="content">
                <div className="sub-title mb-10">Case management:</div>
                <p className="desc">
                  Case management in this context means that the Firebird, LLC caseworker takes responsibility for assuring that the needs of the family as a whole are assessed, that service plans are created with the family and reflect their priorities and goals, and that the efforts of all service providers involved with the family are coordinated and consistent with the service plan. The goals of family-centered case management are:
                </p>
                <div className="content white-bg">
                  <div className="course-overview">
                    <div className="inner-box">
                      <div className="row">
                        <div className="col-lg-12">
                          <ul className="review-list">
                            <li>To develop joint service plans which delineate the roles and functions of all agencies involved with the family</li>
                            <li>To ensure that the efforts of all agencies are directed toward common goals;</li>
                            <li>To ensure that methods and techniques of various service providers do not conflict or confuse family members; and</li>
                            <li>To ensure assessment of a family’s need for follow-up services, and to plan with appropriate agencies for follow-up services to be provided.</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <p className="desc">The worker provides family members with information on the array of useful community resources and helps the family access necessary services. The family makes its own decisions about which services it will use and participates in meetings with service providers.</p>
              </div>
            </div>
            <div id="rs-about" className="rs-about style9 pb-10 md-pt-30 mb-20">
              <div className="">
                <div className="row align-items-center">
                  <div className="col-lg-5 col-md-12 md-mb-20 mb-40">
                    <div className="content d-md-none">
                      <div className="sub-title mb-20">Development and enhancement of parenting skills:</div>
                    </div>
                    <div className="img-part">
                      <img src={serve3Img} alt='Image' />
                    </div>
                  </div>
                  <div className="col-lg-7 pl-100 md-pl-14 col-md-12">
                    <div className="content">
                      <div className="sub-title mb-20 d-none d-md-block">Development and enhancement of parenting skills:</div>
                      <div className="content white-bg">
                        <div className="course-overview">
                          <div className="inner-box">

                            <p className="desc">
                              Through the assessment, counseling, and case management processes, parenting skill deficits will be identified. The worker offers instruction in these skills and provides support as parents implement newly learned skills. This involves a wide range of parenting activities and functions such as nurturing, age appropriate expectations, adequate supervision, acceptable discipline, behavior management, communication, anger control, child development education, etc.
                            </p>



                          </div>
                        </div>

                      </div>

                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 md-mb-40">
              <div className="content">

                <div className="sub-title mb-10">Concrete services</div>
                <p className="desc">
                  Beyond these required services, it is expected that Firebird, LLC programs will provide or help families obtain a broad range of therapeutic, supportive and concrete services to address the particular objectives in the families’ own treatment plans. Concrete services are not required, but may be necessary and are often effective in Firebird, LLC interventions. Concrete services are tangible services provided by Firebird, LLC worker or agency. Some examples are financial assistance, household chores or repairs, and transportation.
                </p>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div
        id="rs-about"
        className="rs-about style9 "
      >
        <div className="container">
          <div className="content">
            <div className="sub-title mb-10">Intervention Strategies:</div>
            <div className="col-lg-12 col-md-12 md-mb-40">
              <p className="desc">Firebird, LLC caseworkers are expected to possess a wide range of skills and abilities in crisis intervention, assessment, counseling/therapy, communication, and teaching. They must be flexible, sensitive to multi-cultural differences and able to work effectively with families of diverse backgrounds. With these skills and abilities they must draw from an extensive repertoire of specific strategies and techniques to meet the diverse needs of diverse families. Among the intervention strategies utilized in family preservation services are:</p>
            </div>

          </div>
        </div>
      </div>

      <div
        id="rs-about"
        className="rs-about style9 pb-50"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-12 md-mb-40">
              <div className="img-part h-100">
                <img src={serve4Img} alt='Image' />
              </div>
            </div>
            <div className="col-lg-7 pl-100 md-pl-14 col-md-12">
              <div className="content">

                <div className="content white-bg">
                  <div className="course-overview">
                    <div className="inner-box">

                      <div className="row">
                        <div className="col-lg-6">
                          <ul className="review-list">
                            <li>Defusing, engaging and confronting client</li>
                            <li>Family assessment and goal setting</li>
                            <li>Teaching behavior management, problem solving and communication skills</li>
                            <li>Teaching anger management</li>
                            <li>Teaching assertiveness skills to families</li>

                          </ul>
                        </div>
                        <div className="col-lg-6">
                          <ul className="review-list">
                            <li>Teaching parenting skills</li>
                            <li>Child development education</li>
                            <li>Developing individualized plans and techniques with families to help manage addiction, depression or violence among family members</li>
                            <li>The use of networking and effective referrals to on-going services</li>
                          </ul>
                        </div>

                      </div>


                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>




    </React.Fragment>
  );
};

export default Team;
