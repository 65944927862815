import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const MenuItemsLeft = (props) => {
    const { parentMenu, secondParentMenu } = props;

    const location = useLocation();

    return (
        <React.Fragment>
           
            <li className={location.pathname === "/" ? 'menu-item-has-children current-menu-item' : 'menu-item-has-children'}>
                <Link to="/" >Home</Link>
            </li>
            <li className={location.pathname === "/about" ? 'menu-item-has-children current-menu-item' : 'menu-item-has-children'}>
                <Link to="/about">About</Link>
            </li>
            
        </React.Fragment>
    );
}

export default MenuItemsLeft;