import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const MenuItemsRight = (props) => {
    const { parentMenu, secondParentMenu } = props;

    const location = useLocation();

    return (
        <React.Fragment>
           
            <li className={location.pathname === "/services" ? 'menu-item-has-children current-menu-item' : 'menu-item-has-children'}>
                <Link to="/services">Services</Link>
            </li>
            <li className={location.pathname === '/contact' ? 'menu-item-has-children current-menu-item' : 'menu-item-has-children'}>
                <Link to="/contact">Contact</Link>
            </li>
            
        </React.Fragment>
    );
}

export default MenuItemsRight;