import React from 'react';
import OffWrap from '../../components/Layout/Header/OffWrap';
import Header from '../../components/Layout/Header/Header';
import Footer from '../../components/Layout/Footer/Footer';
import SearchModal from '../../components/Layout/Header/SearchModal';
import ScrollToTop from '../../components/Common/ScrollTop';
import ContactMain from './ContactMain';

// Image
import favIcon from '../../assets/img/fav-orange.png';
import Logo from '../../assets/img/logo/dark-logo.png';
import footerLogo from '../../assets/img/logo/lite-logo.png';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
import bannerbg from '../../assets/img/breadcrumbs/2.jpg';

const Contact = () => {
    return (
        <React.Fragment>
            <OffWrap />
            <Header
                TopBar='enable'
                emailAddress='admin@firebirdllc.com'
                Location='601 S 10th St., #104, Las Vegas, Nevada 89101'
                parentMenu='home'
            />
            {/* Header */}
            <div className='pageOffset'>
             <SiteBreadcrumb
                    pageTitle="Contact"
                   
                    breadcrumbsImg={bannerbg}
                />
                {/* ContactMain Section Start */}
                <ContactMain />
            </div>

            {/* Footer */}
            <Footer
                footerClass="rs-footer home9-style main-home"
                footerLogo={footerLogo}
            />
            {/* Footer */}

            {/* SearchModal */}
            {/* <SearchModal /> */}
            {/* SearchModal */}

            {/* scrolltop-start */}
            <ScrollToTop
                scrollClassName="scrollup orange-color"
            />
            {/* scrolltop-end */}
        </React.Fragment>

    );
}


export default Contact;