import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

const RSMobileMenu = ({ setMenuOpen, menuOpen, parentMenu, secondParentMenu, headerFullWidth }) => {

	const location = useLocation();



	return (
		<div className={headerFullWidth ? "container-fluid relative" : "container relative"}>
			<div className={menuOpen ? "mobile-menu-part open" : "mobile-menu-part"}>
				<div className="mobile-menu">
					<ul className="nav-menu">
						<li className="menu-item">
							<Link to="/" className={parentMenu === '/' ? 'active-menu' : ''}>Home</Link>							
						</li>
						<li className="menu-item">
							<Link to="/about" className={parentMenu === 'about' ? 'active-menu' : ''}>About</Link>							
						</li>
						<li className="menu-item">
							<Link to="/services" className={parentMenu === 'services' ? 'active-menu' : ''}>Services</Link>							
						</li>
						<li className="menu-item">
							<Link to="/contact" className={parentMenu === 'contact' ? 'active-menu' : ''}>Contact</Link>							
						</li>
					</ul>
				</div>
			</div>
		</div>
	)
}

export default RSMobileMenu;