import React from "react";
import { Link } from "react-router-dom";
import xicon from "../../../assets/img/x-twitter.svg";

const FooterBottom = () => {
  return (
    <div className="footer-bottom">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 md-mb-10">
            <div className="copyright md-mb-0 text-center">
              {/* <a href="" target="_blank">
                <i className="fab fa-facebook-f text-white mr-10"></i>
              </a>
              <a href="" target="_blank">
                <img src={xicon}  style={{height:"30px"}}/>
              </a> */}

              <p className="mt-10">© 2024 All Rights Reserved @ A365.io</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterBottom;
