import React from 'react';
import { Route, BrowserRouter as Router, Switch, withRouter } from 'react-router-dom';

//Custom Components

import Home from '../pages/home';
import About from '../pages/about';
import Contact from '../pages/contact';
import Error from '../pages/404';
import LoadTop from '../components/Common/ScrollTop/LoadTop'
import Services from '../pages/service';
import SES from '../pages/ses';
import PreSes from '../pages/pre-ses';
import Covid from '../pages/covid-19';
import PDFViewer from '../pages/pdf-viewer';

const App = () => {
    return (
        <div className='App'>
            <Router>
                <LoadTop />
                <Switch>
                    <Route path="/" exact component={Home} />
                    <Route path="/home" component={Home} />
                    <Route path="/about" component={About} />
                    <Route path="/services" component={Services} />
                    <Route path="/contact" component={Contact} />
                    <Route path="/pre-ses" component={PreSes} />
                    <Route path="/ses" component={SES} />
                    <Route path="/covid-19" component={Covid} />
                    <Route path="/pdf-viewer" component={PDFViewer} />
                    <Route component={Error} />
                </Switch>
            </Router>

        </div>
    );
}

export default App;
