import React, { useState } from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import SectionTitle from '../../components/Common/SectionTitle';

// About Image
import countIcon1 from '../../assets/img/about/style3/icons/1.png';
import countIcon2 from '../../assets/img/about/style3/icons/2.png';
import countIcon3 from '../../assets/img/about/style3/icons/3.png';

const ServiceCounter = () => {

    const [state, setState] = useState(true);

    const counters = [
        {
            countNum: 2,
            countTitle: 'Students',
            counterPrefix: 'k+',
            countIcon: countIcon1
        },
        {
            countNum: 50,
            countTitle: 'Average CGPA',
            counterPrefix: '',
            countIcon: countIcon2
        },
        {
            countNum: 95,
            countTitle: 'Graduates',
            counterPrefix: '%',
            countIcon: countIcon3
        }

    ];

    return (
        
             
            <div id="rs-about" className="rs-about style1 pt-80 md-pt-30 pb-80 md-pb-0">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 pr-50 md-pr-14">
                        <div className="about-part">
                            <div className="sec-title">
                                <div className="content">
                  <div className="course-overview">
                    <div className="inner-box">
                     
                      <h3>Firebird, LLC Non-Intensive programs are required to provide the following services:</h3>
                      <div className="row">
                      <div className="col-lg-12">
                      <ul className="review-list">
                        <li>Family assessment</li>
                        <li>Family and individual counseling</li>
                        <li>Client advocacy</li>
                        <li>Case management/Referral to other services, as appropriate</li>
                        <li>Development and enhancement of parenting skills</li>
                   
                      </ul>
                      <h3>*Optional services may include: Concrete services, parenting education classes, anger management classes, etc.</h3>
                      </div>
                      </div>
                     
                    </div>
                  </div>
        
                </div>
    
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       
    );
}

export default ServiceCounter;