import React from 'react';
import Header from '../../components/Layout/Header/Header';
import Footer from '../../components/Layout/Footer/Footer';
import OffWrap from '../../components/Layout/Header/OffWrap';
import SearchModal from '../../components/Layout/Header/SearchModal';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
import AboutMain from './AboutMain';

// Image
import Logo from '../../assets/img/logo/dark-logo.png';
import footerLogo from '../../assets/img/logo/lite-logo.png';

import bannerbg from '../../assets/img/breadcrumbs/2.jpg';

const About = () => {

    return (
        <React.Fragment>
            <OffWrap />
            <Header
                TopBar='enable'
                emailAddress='admin@firebirdllc.com'
                Location='601 S 10th St., #104, Las Vegas, Nevada 89101'
                parentMenu='home'
            />
            <div className='pageOffset'>
                {/* breadcrumb-area-start */}
                <SiteBreadcrumb
                    pageTitle="About"
                   
                    breadcrumbsImg={bannerbg}
                />
                {/* breadcrumb-area-start */}

                {/* About Main */}
                <AboutMain />
                {/* About Main */}

            </div>

            <Footer
                footerClass="rs-footer home9-style main-home"
                footerLogo={footerLogo}
            />
            <SearchModal />
        </React.Fragment>
    );
}

export default About;