import React from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import SingleTestimonialTwo from '../../components/Testimonial/SingleTestimonialTwo';

import donorImg from '../../assets/img/donor/1.jpg';
import home1 from '../../assets/img/banner/home/home-1.jpg'
import home2 from '../../assets/img/banner/home/home-2.jpg'
import home3 from '../../assets/img/banner/home/home-3.jpg'
import avatar1 from '../../assets/img/testimonial/style2/1.jpg';
import avatar2 from '../../assets/img/testimonial/style2/2.jpg';

function Testimonial() {

    useEffect(() => {
        AOS.init();
    })

    return (
        <React.Fragment>
            <div className="rs-testimonial style2 pt-100 pb-100 md-pt-0 md-pb-0">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 md-mb-30">
                            <div className="donation-part"> 
                                <img
                                    src={home1}
                                    alt="Donor"
                                />
                               <h3 className="title mb-10">Philosophy</h3>
                                <div className="desc mb-38">Firebird, LLC.’s goal is to help our clients create productive, happier lives by providing quality professional services. Our employee/contractors play a vital role in ensuring that our commitment to excellence is reflected in our service to clients.</div>
                               
                            </div>
                        </div>
                        <div className="col-lg-4 md-mb-30">
                            <div className="donation-part">
                                <img
                                    src={home2}
                                    alt="Donor"
                                />
                                <h3 className="title mb-10">How We Succeed</h3>
                                <div className="desc mb-38">Firebird, LLC provides individualized services to assist individuals to recognize their strengths and overcome challenges that surround their lives. Our approach is client centered so that each person can process information based on their most effective learning style to assist them with their transition.</div>
                               
                            </div>
                        </div>
                        <div className="col-lg-4 md-mb-30">
                            <div className="donation-part">
                                <img
                                    src={home3}
                                    alt="Donor"
                                />
                                <h3 className="title mb-10">Who We Serve</h3>
                                <div className="desc mb-38">Currently, we serve individuals between the ages of 18 and 90. Also, families eligible for non-intensive family preservation are those with one or more children ages 5 through 18 years who are at risk for current or future role dysfunction that could lead to out-of-home placement.</div>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Testimonial